import { AlertBox, AlertBoxDismissButton, BoundedContent, Typography } from '@vp/swan'
import { useSwanStyleKeys, } from '@vp/ubik-context'

export interface Props {
  enabled?: boolean
  message?: string
}

export const Fragment = (props: Props) => {
  const { enabled, message } = props

  useSwanStyleKeys(['alertBox'])

  if (!enabled || !message) return null

  return (
    <BoundedContent style={{ maxWidth: '1440px' }}>
      <AlertBox mt={8}>
        <Typography fontSize='standard' textAlign='center' fontWeight='normal'>
          {message}
        </Typography>
        <AlertBoxDismissButton accessibleText='Dismiss alert' />
      </AlertBox>
    </BoundedContent>
  )
}
